import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ServicesList from '../../components/ServicesList';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "บริการของเรา",
      "style": {
        "position": "relative"
      }
    }}>{`บริการของเรา`}<a parentName="h1" {...{
        "href": "#%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B9%80%E0%B8%A3%E0%B8%B2",
        "aria-label": "บริการของเรา permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <p>{`ที่ Anndream เราให้บริการด้วยความมุ่งมั่น เพื่อแก้ปัญหาให้กับลูกค้าด้วยความจริงใจ ด้วยทีมงานที่มีใจรักการพัฒนาซอฟแวร์ที่กระหายในความรู้ใหม่ๆ รายได้ส่วนหนึ่งเราตอบแทนคืนสู่สังคม และเราสนับสนุนโลกของการแบ่งปันแบบ Opensoure ซึ่งคุณสามารถเป็นส่วนหนึ่งของการแบ่งปันนี้ได้ด้วยการจ้างงานพวกเค้า ขอบคุณสำหรับลูกค้าทุกท่านที่เคยใช้บริการของพวกเรา`}</p>
    <ServicesList mdxType="ServicesList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      